import React from 'react';
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>济南良辰信息科技有限公司</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          济南良辰信息科技有限公司，一家致力于努力挑选当下优秀图书作品服务大众，让用户读到好书、学到真东西的业内新星。
        </p>
      </span>
    ),
  },
};
export const Content70DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '我们提供专业的用户服务',
        className: 'title-h1',
      },
      { name: 'content', children: '专注自我成长、运动、兴趣技能等多个领域' },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: { children: '公司简介', className: 'content7-tag-name' },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h3>重点聚焦</h3>
                <p>
                  公司重点聚焦于用户自我成长、兴趣技能、修身养性等多个领域。带你开启智慧宝藏的大门，领略无尽的知识奥秘，满足你对知识的热切渴望。我们精心挑选一系列高质量且独具特色的知识产品，广泛涵盖各个学科与主题，致力于满足用户对知识的多元化需求和深度探索。
                </p>
                <br />
                <h3>经营理念</h3>
                <p>
                  公司始终秉持专业专注、开拓创新、追求卓越品质的理念，以品质为魂，怀揣着对未来的无限憧憬，致力于打造独更好的购书的体验。我们持续探索与奋进，旨在为广大用户呈上最为优质的产品与服务，引领自我成长、兴趣技能、修身养性等领域不断向前发展，为人们的美好生活增添更多丰富色彩与独特价值。
                </p>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          text: { children: '业务&愿景', className: 'content7-tag-name' },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h3>业务能力</h3>
                <p>
                  我们深入挖掘并积极推广形式多样的图书，使您尽情享受阅读带来的更多益处。我们推出科学合理的健康方案与贴心建议，全力帮助人们改善生活品质，长久保持健康状态。
                </p>
                <br />
                <h3>业务目标</h3>
                <p>
                  终身学习与文化产业，成人自我成长，让健康与快乐长存。
                </p>
                <br />
                <h3>
                  愿景与使命
                </h3>
                诚信立根本 多元重体验 让知识提升自我。
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper feature7-wrapper-img' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <p>热销图书</p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children: (
                <img src="/static/img/company_1.jpg" alt="img"></img>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/company_2.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/company_3.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/company_4.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/company_5.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block5',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/company_6.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block6',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/company_7.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/company_8.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
    ],
  },
};

import React from 'react';
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>大三考研复习计划</p>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>助力考研，让您更上一层楼。</p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-content',
      },
      {
        name: 'pricing',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>¥1</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-pricing lxn2ppamdl6-editor_css',
      },
      {
        name: 'button',
        children: {
          icon: 'shopping-cart',
          type: 'primary',
          children: (
            <span>
              <span>
                <p>立即报名</p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};
export const Content70DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7 content7-detail' },
  OverPack: {},
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: { children: '商品详情', className: 'content7-tag-name' },
        },
        content: {
          className: 'content7-content',
          img: {
            className: 'content7-img',
            md: 24,
            xs: 24,
            children:
              '/static/img/detail.jpg',
          },
        },
      },
    ],
  },
};

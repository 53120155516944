import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page lxn25ocsw1b-editor_css' },
  logo: {
    className: 'header3-logo',
    children:
      '/static/img/logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#/company',
          children: [
            {
              children: (
                <span>
                  <p>公司简介</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '#/detail',
          children: [
            {
              children: (
                <span>
                  <p>购买服务</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0' },
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page pricing0' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children:
      '/static/img/4.jpg',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>良辰优选图书</p>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      一家致力于精选优秀图书的企业，国学易经、修身养性、食补大全、学生教辅、副业赚钱等等，专注选取优异图书服务用户，争取给大家提供更有益的书籍。
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-content',
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <p>服务内容</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: (
          <span>
            <p>丰富多样的书籍选择</p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/customerservice-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>国学易经</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        彩色图解，古籍典藏，经典传承。
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/read-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>梅花易数</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    以易学中的数学为基础，结合易学中的“象学”进行占卜。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/compass-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>经洛按摩</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        通过手法按摩特定的穴位和经络,调理身体、预防和治疗疾病
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/shopping-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>百病食疗</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>
                      通过食疗调理身体，改善体质，提高免疫力，预防疾病
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/funnelplot-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>黄帝内经</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    所谓“医经”，就是阐发人体生理、病理、诊断、治疗和预防等医学理论之著作
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block5',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/video-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>手机剪辑</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          新手小白快速入门，零基础学剪辑
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block6',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/appstore-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>用钱赚钱</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    树立正确的金钱观念，实现自我价值，改善生活质量
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/cloud-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>围棋弈数</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>将科学、艺术和竞技三者融为一体，开发智力，培养意志</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};export const Feature71DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper feature7-wrapper-img' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <p>热销图书</p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 8,
        xs: 24,
        name: 'block8',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/2.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block9',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/3.jpg" alt="img" style={{"border": "1px solid", "border-color": "#CCC"}}></img>
              )
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block10',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/4.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block11',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/5.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block12',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/6.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block13',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image2',
              children:(
                <img src="/static/img/7.jpg" alt="img"></img>
              )
            },
          ],
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>售后服务</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    售后客服15小时在线答疑解惑，任何问题极速退款
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright©2024 良辰信息 All Rights
        Reserved <a href="https://beian.miit.gov.cn/">鲁ICP备2024110613号-1</a>
      </span>
    ),
  },
};
